import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import react from 'react';
import html2canvas from 'html2canvas';
import withNeon, { Fx } from "react-neon/dist/index.babel.js";
const Tile = () => (<div style={{ height: 200, width: 300, backgroundColor: '#f00' }}>My Tile</div>);
class MyNeonEffect extends Fx {
    init() {
        this.comcanvas = null;
        this.html = html2canvas(this.component, { scale: 1 }).then(canvas => {
            this.comcanvas = canvas;
        });
        this.s = 0;
    }
    draw() {
      if (this.ctx!==null && this.comcanvas!==null) {
        this.ctx.clearRect(0, 0, this.bb.width, this.bb.height);
        this.s += 0.05;
        for (let x=0; x<this.bb.width; x++) {
            this.ctx.drawImage(this.comcanvas, x,0, 1,this.bb.height, x,(5*Math.sin((x*0.05)+this.s)), 1,this.bb.height);
        }
      }
      this.raf = requestAnimationFrame(this.draw);
    }
}
const effect = new MyNeonEffect();
const CircleTile = withNeon(Tile, effect);
export const frontmatter = {
  title: 'HTML2Canvas',
  author: 'Chris Neale',
  draft: true,
  date: '2016-09-03T23:46:37.121Z',
  tags: ['effects']
}
export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>


<MDXTag name="h3" components={components}>{`HTML2Canvas Example`}</MDXTag>
<CircleTile /></MDXTag>

export const _frontmatter = {};

  